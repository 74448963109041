import React, {useState, useEffect} from 'react'
import {animateScroll} from 'react-scroll'
import {withGlobal} from '../shared/app'
import GmapInput from '../misc/gmap-input'
import {Btn} from '../misc/buttons'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import momenttz from 'moment-timezone'
import InputMask from 'react-input-mask'
import DateInput from '../misc/date-input'

const Block = ({
  pendingLocations,
  setPendingLocations = () => {},
  max = 8,
  t,
  lang,
  updateErrors = [],
  Beacons,
  Products
}) => {
  const tz = moment.tz.guess()

  const defaultPlace = () => ({
    name: '',
    address: '',
    tz,
    meta: {
      type: 'ceremony', // enum 'ceremony', 'contemplation', 'interment', 'cremation', 'inhumation'
      customType: '',
      privacy: false,
      date: '',
      info: '',
      broadcast: ''
    }
  })

  const sanitize = (places = []) =>
    places.map((p) => {
      if (!p.tz) p.tz = tz // assign tz if missing
      if (p.meta.customType == undefined) p.meta.customType = ''
      if (!p._key) p._key = Math.round(Math.random() * 10000) // assign random key for react iteration key
      if (!p.info) p.info = ''
      if (!p.meta.broadcast) p.meta.broadcast = ''
      return p
    })

  pendingLocations = sanitize(pendingLocations || [defaultPlace()])

  const [items, setItemsWrap] = useState(pendingLocations)
  const [infoAvailable, setInfoAvailable] = useState({})

  useEffect(() => {
    setInfoAvailable(
      ...items.map((place) => {
        let ret = {}
        let key = place._key
        ret[key] = place.meta.info || place.meta.broadcast
        return ret
      })
    )
  }, [])

  const setItems = (items) => {
    items = sanitize(items)
    setPendingLocations(items)
    setItemsWrap(items)
  }

  const getChange = (e) => {
    const {target} = e
    const value = target.type === 'checkbox' ? target.checked : target.value
    return {[target.name]: value}
  }

  const onItemChange = (e, index, subkey) => {
    const change = getChange(e)

    // special case (reset customType)
    if (change.type && change.type != 'custom') change.customType = undefined

    const updatedItems = [...items]
    if (subkey) updatedItems[index][subkey] = {...updatedItems[index][subkey], ...change}
    else updatedItems[index] = {...updatedItems[index], ...change}

    setItems([...updatedItems])
  }

  const onNew = () => {
    const updatedItems = [...items]
    updatedItems.push(defaultPlace())
    setItems([...updatedItems])
    animateScroll.scrollMore(300)
  }

  const onDelete = (index) => {
    let updatedItems = items.filter((item, i) => (i == index ? false : item))

    setItems([...updatedItems])
  }

  const setPlaceDateTz = (place, tz) => {
    let date = moment(place.meta.date)
    date = date.tz(tz, true)
    return date
  }

  const setTime = (date, h, m) => {
    date.hour(h)
    date.minute(m)
    return date
  }

  const getTime = (date) => {
    date = moment(date)
    const hour = date.hours()
    const minute = date.minutes()
    return [hour, minute]
  }

  const getPlaceTime = (place) => {
    const date = moment(place.meta.date).tz(place.tz)
    const [hour, minute] = getTime(date)
    return [hour, minute, place.tz]
  }

  const onTimeChange = (e, index) => {
    let time = e.target.value.replace(/\s/g, '')
    let [hour, minute] = time.split(':')
    let date = moment(items[index].meta.date).tz(items[index].tz)
    // if we get 4 digit go on
    if (/[0-9]{4}/.test(`${hour}${minute}`)) {
      hour = parseInt(hour, 10)
      minute = parseInt(minute, 10)
      // if time is valid update place.date
      if (hour < 24 && minute < 60) {
        date = setTime(date, hour, minute)

        const updatedItems = [...items]
        updatedItems[index].meta = {...updatedItems[index].meta, date: date.format()}
        setItems([...updatedItems])
      }
    }
  }

  const onDateChange = (index) => (date) => {
    if (!date) {
      items[index].meta = {...items[index].meta, date: ''}
      return setItems([...items])
    }

    const item = items[index]
    date = date.tz(item.tz, true)
    const [hour, minute] = getPlaceTime(item)
    date = setTime(date, hour, minute)

    if (!date._isValid) return

    items[index].meta = {...items[index].meta, date: date.format()}
    setItems([...items])
  }

  const onAddressChange = (index) => {
    return (address) => {
      if (address && Object.keys(address.geocode).length) {
        const item = items[index]
        if (item.meta.date) {
          let date = setPlaceDateTz(item, address.tz)
          const [hour, minute] = getPlaceTime(item)
          date = setTime(date, hour, minute)

          items[index] = {
            ...item,
            zipcode: '',
            city: '',
            country: '',
            country_code: '',
            latlng: '',
            place_id: '',
            region: '',
            state: '',
            street: '',
            street_number: '',
            address: '',
            additionalInfo: '',
            ...address.geocode,
            ...(address.address && {address: address.address}),
            ...(address.latlng && {latlng: address.latlng}),
            meta: {...item.meta, date: date.format()}
          }
        } else {
          items[index] = {
            ...item,
            zipcode: '',
            city: '',
            country: '',
            country_code: '',
            latlng: '',
            place_id: '',
            region: '',
            state: '',
            street: '',
            street_number: '',
            address: '',
            additionalInfo: '',
            ...address.geocode,
            ...(address.address && {address: address.address}),
            ...(address.latlng && {latlng: address.latlng})
          }
        }

        setItems([...items])
      } else {
        const item = items[index]
        items[index] = {
          ...item,
          zipcode: '',
          city: '',
          country: '',
          country_code: '',
          latlng: '',
          place_id: '',
          region: '',
          state: '',
          street: '',
          street_number: '',
          address: '',
          additionalInfo: '',
          ...(address.address && {address: address.address})
        }
        setItems([...items])
      }
    }
  }

  const addInfo = (index) => {
    let key = items[index]._key
    infoAvailable[key] = true
    setInfoAvailable({...infoAvailable})
  }

  const hasUpdateError = (location) => {
    return updateErrors.filter((update) => update.value._key === location._key).length > 0
  }

  const broadcastQnA = t('places.broadcast.qnaId', false)

  return (
    <div className=''>
      <div className='d-flex flex-column'>
        {(items || []).map((location, i) => {
          return (
            <div className={`item ${i === items.length - 1 ? 'last' : ''}`} key={location._key}>
              <div className='d-flex flex-column justify-content-between mb-2'>
                {hasUpdateError(location) && (
                  <div className='err-alert alert alert-danger-light p-1 my-2'>
                    {t('snackbar.default.error')}
                  </div>
                )}

                <div className='form-group mb-0'>
                  <label className='form-label mt-0'>
                    {t('places.labels.event')} n°{i + 1}
                  </label>
                  <select
                    name='type'
                    value={location.meta.type}
                    onChange={(e) => onItemChange(e, i, 'meta')}
                    className='form-control'>
                    {Object.keys(t('places.types', true)).map((type, y) => (
                      <option key={y} value={type}>
                        {t(`places.types.${type}`)}
                      </option>
                    ))}
                    <option value=''>---------</option>
                    <option value='custom'>{t('places.labels.custom-type')}</option>
                  </select>
                </div>

                {location.meta.type == 'custom' && (
                  <div className='form-group mb-0 mt-1'>
                    <input
                      name='customType'
                      value={location.meta.customType}
                      placeholder={t('places.ph.custom-type')}
                      onChange={(e) => onItemChange(e, i, 'meta')}
                      type='text'
                      className='form-control'
                    />
                  </div>
                )}

                <div className='form-group p-2 mb-2'>
                  <label className='switch d-inline-flex align-items-center justify-content-between cursor m-0'>
                    <small className='mr-2 text-muted'>{t('places.labels.privacy')}</small>
                    <input
                      type='checkbox'
                      name='privacy'
                      checked={location.meta.privacy}
                      onChange={(e) => onItemChange(e, i, 'meta')}
                    />
                    <span className='switcher'></span>
                  </label>
                </div>

                <div className='form-group date-input d-flex justify-content-between'>
                  <div style={{flex: '1'}}>
                    <label className='form-label'>{t('places.labels.date')}</label>
                    <DateInput
                      value={location.meta.date ? moment(location.meta.date).tz(location.tz) : null}
                      onChange={onDateChange(i)}
                      locale={lang}
                      minDate={moment().add(-10, 'years')}
                      maxDate={moment().add(10, 'years')}
                      placeholderText={t('places.labels.date')}
                      attached
                    />
                  </div>
                  <div className='' style={{width: '120px', marginLeft: '-1px'}}>
                    <label className='form-label'>{t('places.labels.time')}</label>
                    <InputMask
                      inputMode='numeric'
                      defaultValue={
                        location.meta.date &&
                        moment(location.meta.date).tz(location.tz).format('HH:mm')
                      }
                      mask='H9 : 99'
                      maskChar='_'
                      formatChars={{9: '[0-9]', a: '[A-Za-z]', '*': '[A-Za-z0-9]', H: '[0-2]'}}
                      onChange={(e) => onTimeChange(e, i)}
                      className='form-control text-center radius-left-none'
                      placeholder='hh : mm'
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='form-label'>{t('places.labels.name')}</label>
                  <input
                    type='text'
                    name='name'
                    value={location.name}
                    onChange={(e) => onItemChange(e, i)}
                    className='form-control'
                    placeholder={t('places.ph.name')}
                  />
                </div>
                <div className='form-group' style={{position: 'relative'}}>
                  <label className='form-label'>{t('places.labels.address')}</label>
                  <GmapInput
                    value={location.address}
                    onChange={onAddressChange(i)}
                    placeholder={t('places.ph.address')}
                    className='form-control'
                  />
                </div>

                {infoAvailable[location._key] && (
                  <div className='form-group'>
                    <div className='d-flex flex-row align-items-center form-control mb-3 pl-0'>
                      <div style={{flex: '1'}}>
                        <input
                          type='text'
                          name='broadcast'
                          value={location.meta.broadcast}
                          onChange={(e) => onItemChange(e, i, 'meta')}
                          className='form-control'
                          style={{border: 0, backgroundColor: 'transparent'}}
                          placeholder={t('broadcast.labels.link')}
                        />
                      </div>

                      {Beacons.available() && broadcastQnA && (
                        <img
                          className='d-block qna'
                          onClick={() => {
                            Beacons.article(broadcastQnA)
                          }}
                          src='https://res.cloudinary.com/inmemori/image/upload/web-assets/picto/help.png'
                          height='20px'
                        />
                      )}
                    </div>
                    <textarea
                      name='info'
                      value={location.meta.info}
                      onChange={(e) => onItemChange(e, i, 'meta')}
                      maxLength='200'
                      placeholder={t('places.ph.info')}
                      rows='3'
                      className='form-control'
                    />
                  </div>
                )}
              </div>

              {Products.Flower.isActive() && (
                <div className='form-group p-2 mb-2'>
                  <label className='switch d-inline-flex align-items-center justify-content-between cursor m-0'>
                    <small className='mr-2 text-muted'>{t('places.labels.disableFlower')}</small>
                    <input
                      type='checkbox'
                      name='disableFlower'
                      checked={location.meta.disableFlower}
                      onChange={(e) => onItemChange(e, i, 'meta')}
                    />
                    <span className='switcher'></span>
                  </label>
                </div>
              )}

              <div className='d-flex flex-row-reverse justify-content-between pl-1 pr-1'>
                <Btn
                  className='btn btn-link text-danger'
                  onClick={() => onDelete(i)}
                  value={t('places.cta.delete')}
                  icon='https://res.cloudinary.com/inmemori/image/upload/v1589806556/web-assets/picto/delete.svg'
                />
                {!infoAvailable[location._key] && (
                  <Btn
                    className='btn btn-link text-primary'
                    onClick={() => addInfo(i)}
                    value={t('places.cta.addInfo')}
                  />
                )}
              </div>
            </div>
          )
        })}{' '}
        {/* END ITEM */}
        {items.length < max && (
          <div className='item'>
            <Btn
              className='btn btn-link text-primary'
              onClick={() => onNew()}
              value={t('places.cta.add')}
              icon='https://res.cloudinary.com/inmemori/image/upload/v1589806556/web-assets/picto/calendar.svg'
            />
          </div>
        )}
      </div>
      {/* END GRID */}

      <style jsx>
        {`
          .form-label {
            display: none;
          }

          .item:not(:first-child) {
            border-top: 1px solid #dadada;
            padding-top: 20px !important;
            margin-top: 20px;
          }
          .item .form-group:last-child {
            margin-bottom: 0px !important;
          }

          .date-input input:eq(0) {
            border: 2px solid red;
          }

          .qna {
            cursor: pointer;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
