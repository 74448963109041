import React, {useState, useEffect} from 'react'
import CountryFlag from './country-flag'
import _ from 'lodash'
import {phoneToData} from '../../lib/utils'

const $Zones = [
  {country: 'fr', name: 'france', indicatif: '+33'},
  {country: 'us', name: 'united states', indicatif: '+1'},
  {country: 'mx', name: 'mexico', indicatif: '+52'},
  {country: 'be', name: 'belgique', indicatif: '+32'},
  {country: 'ch', name: 'suisse', indicatif: '+41'},
  {country: 'de', name: 'deutschland', indicatif: '+49'},
  {country: 'es', name: 'spain', indicatif: '+34'},
  {country: 'gb', name: 'united kingdom', indicatif: '+44'},
  {country: 'it', name: 'italy', indicatif: '+39'},
  {country: 'cl', name: 'chile', indicatif: '+56'}
]

export const getPhone = (input, defaultCountry = 'fr', forceDefault) => {
  const zonesByCountry = _.keyBy($Zones, 'country')
  const zone = zonesByCountry[defaultCountry.toLowerCase()]
  if (!zone) return false

  const phone = phoneToData(input, zone.country, forceDefault)
  const indicatif = (phone && phone.indicatif) || zone.indicatif
  const raw = typeof input == 'object' ? input.raw : input
  const clean = phone && phone.number
  const country = (phone && phone.country) || zone.country

  const result = {
    indicatif,
    clean,
    raw,
    country
  }

  return result
}

const zoneItem = (zone) => {
  return (
    <span className=''>
      <CountryFlag country={zone.country} className='mr-2' />
      <small>{zone.indicatif}</small>
    </span>
  )
}

const Input = ({
  defaultPhone = {},
  status,
  name = 'phone',
  onChange,
  countries,
  defaultCountry,
  disabled,
  placeholder = 'Phone',
  className = '',
  isInvalid = false
}) => {
  const [phone, setPhone] = useState(getPhone(defaultPhone, defaultCountry))

  countries = !countries
    ? _.map($Zones, (z) => z.country)
    : typeof countries == 'string'
    ? countries.split(',')
    : countries

  const Countries = $Zones.filter((z) => countries.includes(z.country))

  const onZoneChange = (zone) => {
    const newPhone = getPhone(phone.raw, zone.country, true) //{ ...phone, indicatif: zone.indicatif, country: zone.country }
    onChange && onChange(newPhone)
    setPhone(newPhone)
  }

  const onInputChange = (e) => {
    const raw = e.target.value
    const newPhone = getPhone(raw, phone.country)
    if (/stop/gi.test(raw)) newPhone.stopsms = true
    else delete newPhone.stopsms
    setPhone(newPhone)
    onChange && onChange(newPhone)
  }

  useEffect(() => {
    if (defaultPhone && !defaultPhone.raw) {
      setPhone(getPhone(defaultPhone, phone.country || defaultCountry))
    }
  }, [defaultPhone.raw])

  const red = '#FF8F60'
  const green = '#7BB8A5'

  return (
    <div className={`input-group ${className}`}>
      {false && JSON.stringify(phone)}
      <div className='input-group-prepend'>
        <button
          className='btn dropdown-toggle'
          type='button'
          data-toggle='dropdown'
          disabled={disabled}>
          {phone.country && zoneItem(phone)}
        </button>
        <div className='dropdown-menu'>
          {_.sortBy(Countries, 'name').map((z, i) => {
            return (
              <a key={i} className='dropdown-item' onClick={() => onZoneChange(z)}>
                {zoneItem(z)}
              </a>
            )
          })}
        </div>
      </div>

      <input
        type='text'
        inputMode='tel'
        name={name}
        value={phone.raw}
        disabled={disabled}
        onChange={onInputChange}
        className='form-control'
        placeholder={placeholder}
      />

      {status === false && <i className='status-ko fa fa-times-circle' />}
      {status === true && <i className='status-ok fa fa-check-circle' />}

      <style jsx>
        {`
          .dropdown-toggle {
            padding: 0 16px !important;
          }

          .input-group.invalid button,
          .input-group.invalid input {
            border-color: ${red} !important;
          }
          .input-group.invalid input:focus {
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          }
          .input-group.valid button,
          .input-group.valid input {
            border-color: ${green} !important;
          }

          .status-ok,
          .status-ko {
            position: absolute;
            right: 10px;
            top: calc(50% - 8px);
            z-index: 10;
          }
          .status-ok {
            color: ${green};
          }
          .status-ko {
            color: ${red};
          }

          .btn {
            border: 1px solid ${isInvalid ? red : '#9e9e9e'} !important;
          }
        `}
      </style>
    </div>
  )
}

export default Input
