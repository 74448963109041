import React from 'react'

const CardTop = ({title, subtitle, icon}) => {
  return (
    <div className='d-none d-md-block text-center mb-3'>
      {icon && <img className='mt-4 mb-3' width='150px;' src={icon} />}
      {title && <h1 className='mt-4 mb-5 d-none d-md-block'>{title}</h1>}
      {subtitle && (
        <p
          className='text-light col-12 col-md-10 col-lg-9 m-auto pb-4 pre-line'
          dangerouslySetInnerHTML={{__html: subtitle}}
        >
        </p>
      )}
      <style jsx>{`
        @media (min-width: 768px) { // md up
          h1 + p {
            margin-top: -40px !important
          }
        }
      `}</style>
    </div>
  )
}
export default CardTop

export const CardTopKeepSubtitle = ({title, subtitle, icon}) => {
  return (
    <div className='text-center mb-3'>
      {icon && <img className='d-none d-md-block mt-4 mb-3 mx-auto' width='150px;' src={icon} />}
      {title && <h1 className='d-none d-md-block mt-4 mb-5 d-none d-md-block'>{title}</h1>}
      {subtitle && (
        <p
          className='text-light col-12 col-md-10 col-lg-9 m-auto pb-4 pre-line'
          dangerouslySetInnerHTML={{__html: subtitle}}
        >
        </p>
      )}
      <style jsx>{`
        @media (min-width: 768px) { // md up
          h1 + p {
            margin-top: -40px !important
          }
        }
      `}</style>
    </div>
  )
}
