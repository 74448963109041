import moment from 'moment'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
// pick a date util library
import MomentUtils from '@date-io/moment'
import {createMuiTheme} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import {withI18n} from '../store/i18n'

const Block = ({
  t,
  locale,
  placeholderText,
  value,
  onChange,
  disableFuture = false,
  disablePast = false,
  minDate,
  maxDate,
  attached = false
}) => {
  const materialTheme = createMuiTheme({
    palette: {
      primary: {main: '#32755f'},
      error: {main: '#FF8F60'}
    },
    overrides: {
      MuiTextField: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#9E9E9E',
              borderWidth: '1px',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: attached ? '0px' : '8px',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: attached ? '0px' : '8px'
            },
            '&:hover fieldset': {
              borderColor: '#9E9E9E',
              borderWidth: '1px'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#9E9E9E',
              borderWidth: '1px'
            },
            '&.Mui-error fieldset': {
              borderColor: '#FF8F60',
              borderWidth: '1px'
            }
          }
        }
      },
      MuiInputBase: {
        root: {
          height: '50px'
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#32755f'
        }
      },
      MuiFormHelperText: {
        contained: {
          margin: '0px !important'
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: '#32755f'
        }
      },
      MuiPickersDay: {
        day: {
          color: '#222222'
        },
        daySelected: {
          backgroundColor: '#32755f'
        },
        dayDisabled: {
          color: '#9E9E9E'
        },
        current: {
          color: '#32755f'
        }
      }
    }
  })

  const errorBlock = (message) => (
    <div className='err-alert alert alert-danger-light p-1 mt-2 mb-0'>{message}</div>
  )

  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
        <KeyboardDatePicker
          className='w-100 form-control h-auto'
          inputMode='numeric'
          autoOk
          clearable
          disableFuture={disableFuture}
          disablePast={disablePast}
          inputVariant='outlined'
          value={value}
          onChange={onChange}
          format={t('dates.picker.format')}
          placeholder={placeholderText}
          color='primary'
          minDate={minDate}
          maxDate={maxDate}
          invalidDateMessage={errorBlock(t('dates.picker.invalid'))}
          maxDateMessage={errorBlock(t('dates.picker.max'))}
          minDateMessage={errorBlock(t('dates.picker.min'))}
          cancelLabel={t('common.cancel')}
          okLabel={t('common.ok')}
          clearLabel={t('common.clear')}
          showTodayButton={false}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default withI18n(Block)
