const NavBar = ({children, onClick = () => {}, showPrev = true}) => (
  <div className='root d-flex d-md-none bg-light justify-content-between align-items-center'>
    {showPrev && (
      <img
        onClick={onClick}
        className='cursor'
        src='https://res.cloudinary.com/inmemori/image/upload/v1589542650/web-assets/picto/arrow.svg'
      />
    )}
    {!showPrev && <div></div>}
    <div className='z2 f2 bold text-center'>{children}</div>
    <div></div>

    <style jsx>
      {`
        .root {
          height: 48px;
          position: sticky;
          top: 0;
          z-index: 100;
        }
        img {
          padding-left: 10px;
          margin-right: -37px !important;
        }
        .z2 {
          font-size: 18px;
          line-height: 18px;
          max-width: 300px;
        }
      `}
    </style>
  </div>
)

export default NavBar
